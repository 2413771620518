import { $apiClient } from 'utils/request';
import { ActionUpdateTrigger, ActionUpdateAvatarTrigger } from './types';

const profileEndpoint = 'api/v1/profile';

export function fetchProfileService() {
  return $apiClient.get(profileEndpoint);
}

export function updateProfileService(payload: ActionUpdateTrigger) {
  return $apiClient.put(profileEndpoint, payload);
}

export function updateAvatarService(payload: ActionUpdateAvatarTrigger) {
  const url = profileEndpoint + '/avatar';

  const requestData = new FormData();

  requestData.append('avatar', payload.avatar);

  return $apiClient.post(url, requestData);
}
