import styled from 'styled-components/macro';

import mq from '../../utils/mediaQueriesBp';

import { ButtonSizes, ButtonGroups } from './types';

interface IStyledProps {
  group: ButtonGroups;
  size: ButtonSizes;
  pageType?: string;
  icon?: boolean;
}

const StyledButton = styled.button.attrs<IStyledProps>(props => ({
  group: props.group || ButtonGroups.Primary,
  size: props.size || ButtonSizes.Regular,
}))<IStyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${props => theme.sizes[props.size].padding};
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: ${props => theme.sizes[props.size].height};
  background-color: ${props => theme.groups[props.group].backgroundColor};
  border: ${props => theme.groups[props.group].border};
  box-shadow: ${props => theme.groups[props.group].boxShadow};

  color: ${props => theme.groups[props.group].color};
  text-decoration: none;
  transition: all 0.2s;
  appearance: none;
  overflow: hidden;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  // font-family: Roboto, sans-serif;

  ${mq('desktop')} {
    height: ${props => theme.sizes[props.size].height};
    padding: ${props => theme.sizes[props.size].padding};
  }
  ${mq('tablet')} {
    font-size: 16px;
  }
  ${mq('laptop')} {
    font-size: 18px;
  }
  ${props =>
    props.icon &&
    `
    svg {
      margin-right: 5px
    }
    `}

  ${props =>
    props.pageType === 'doctor' &&
    `
    border-radius: 0;
    font-weight: 500;
    line-height: 19px;
    
    ${mq('laptop')} {
      font-size: 16px;
    }
  `}

  &:hover {
    background-color: ${props =>
      theme.groups[props.group].hover.backgroundColor};
    color: ${props => theme.groups[props.group].hover.color};
    box-shadow: ${props => theme.groups[props.group].hover.boxShadow};
    border: ${props => theme.groups[props.group].hover.border};
    ${props =>
      props.icon &&
      `
        background-color: ${theme.groups[props.group].backgroundColor};
        border: ${theme.groups[props.group].border};
        box-shadow: ${theme.groups[props.group].boxShadow};
        color: ${theme.groups[props.group].color};
    `}
  }

  &:disabled {
    background-color: ${props =>
      theme.groups[props.group].disabled.backgroundColor};
    color: ${props => theme.groups[props.group].disabled.color};
    box-shadow: ${props => theme.groups[props.group].disabled.boxShadow};
    border: ${props => theme.groups[props.group].disabled.border};
    pointer-events: none;
  }
`;

const theme = {
  sizes: {
    regular: {
      height: '40px',
      padding: '10px 21px',
    },
    large: {
      height: '60px',
      padding: '20px 35px',
    },
    superLarge: {
      height: '120px',
      padding: '10px 21px',
    },
  },
  groups: {
    primary: {
      backgroundColor: '#f55b41',
      color: '#FFFFFF',
      border: 'none',
      hover: {
        backgroundColor: '#FFFFFF',
        color: '#323c8e',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        border: 'none',
      },
      disabled: {
        backgroundColor: '#E9E9E9',
        color: '#FFFFFF',
        boxShadow: 'none',
        border: 'none',
      },
    },
    secondary: {
      backgroundColor: '#323c8e',
      color: '#FFFFFF',
      boxShadow: 'none',
      border: 'none',
      hover: {
        backgroundColor: '#FFFFFF',
        color: '#323c8e',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        border: 'none',
      },
      disabled: {
        backgroundColor: '#E9E9E9',
        color: '#FFFFFF',
        boxShadow: 'none',
        border: 'none',
      },
    },
    tertiary: {
      backgroundColor: '#6DC7DD',
      color: '#FFFFFF',
      boxShadow: 'none',
      border: 'none',
      hover: {
        backgroundColor: '#FFFFFF',
        color: '#6DC7DD',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        border: 'none',
      },
      disabled: {
        backgroundColor: 'transparent',
        color: '#DADADA',
        boxShadow: 'none',
        border: '1px solid #E9E9E9',
      },
    },
    textSecondary: {
      backgroundColor: 'transparent',
      color: '#323c8e',
      boxShadow: 'none',
      border: 'none',
      hover: {
        backgroundColor: 'transparent',
        color: '#323c8e',
        boxShadow: 'none',
        border: 'none',
      },

      disabled: {
        backgroundColor: 'transparent',
        color: '#DADADA',
        boxShadow: 'none',
        border: '1px solid #E9E9E9',
      },
    },
    textTertiary: {
      backgroundColor: 'transparent',
      color: '#6DC7DD',
      boxShadow: 'none',
      border: 'none',
      hover: {
        backgroundColor: 'transparent',
        color: '#6DC7DD',
        boxShadow: 'none',
        border: 'none',
      },
      disabled: {
        backgroundColor: 'transparent',
        color: '#DADADA',
        boxShadow: 'none',
        border: '1px solid #E9E9E9',
      },
    },
    outlinedPrimary: {
      backgroundColor: 'transparent',
      color: '#f55b41',
      boxShadow: 'none',
      border: '1px solid #f55b41',
      hover: {
        backgroundColor: '#f55b41',
        color: '#FFFFFF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        border: '1px solid #f55b41',
      },
      disabled: {
        backgroundColor: 'transparent',
        color: '#DADADA',
        boxShadow: 'none',
        border: '1px solid #E9E9E9',
      },
    },
  },
};

export { StyledButton };
