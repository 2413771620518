import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { useLocation } from 'react-router-dom';

import Logo from '../../../assets/images/GI-Cancer-Expert.png';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as UserMobileIcon } from '../../../assets/icons/user-mobile.svg';
import { ReactComponent as MenuBookmark } from '../../../assets/icons/menu-bookmark.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
} from '../../../store/app/slice';

import { selectIsAuthenticated } from '../../../store/app/selectors';

import {
  sliceKey as profileSliceKey,
  reducer as profileReducer,
  actions as profileActions,
} from '../../../store/profile/slice';

import { profileSaga } from '../../../store/profile/saga';

import { selectProfileData } from '../../../store/profile/selectors';

// components
import MenuItem from './MenuItem';
import StyledBurger from '../../../atoms/Burger';
import BurgerNavMenu from '../NavMenu';

// styles
import {
  Wrapper,
  Inner,
  ActionsContainer,
  LogoOuter,
  NavMenu,
  UserActionButton,
  ButtonIcon,
  ButtonIconBookmark,
  ButtonsContainer,
  Link,
} from './styles';

import { menu } from '../../../config/menu';
import { useWindowSize } from '../../../hooks/useWindowSize';
import {
  actions as favoritesActions,
  reducer as favoritesReducer,
  sliceKey as favoritesSliceKey,
} from '../../../store/favorites/slice';
import { favoritesSaga } from '../../../store/favorites/saga';
import { selectFavoritesData } from '../../../store/favorites/selectors';

export default function NavBar() {
  // inject reducer && saga
  useInjectSaga({ key: profileSliceKey, saga: profileSaga });
  useInjectReducer({ key: appSliceKey, reducer: appReducer });

  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });

  // inject reducer && saga for favorites
  useInjectSaga({ key: favoritesSliceKey, saga: favoritesSaga });
  useInjectReducer({ key: favoritesSliceKey, reducer: favoritesReducer });

  // local state
  const [burgerOpened, setBurgerOpened] = useState(false);

  const [isSticky, setSticky] = useState(true);

  // locales hook
  const { t } = useTranslation();

  // location hook
  const location = useLocation();

  // window size watcher
  const { width } = useWindowSize();

  // dispatch
  const dispatch = useDispatch();
  const getProfile = () => dispatch(profileActions.fetchTrigger());
  const getFavorites = () => dispatch(favoritesActions.fetchTrigger());

  // selector
  const isLoggedIn = useSelector(selectIsAuthenticated);

  const profileData = useSelector(selectProfileData);

  const favoritesData = useSelector(selectFavoritesData);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!profileData && isLoggedIn) {
      getProfile();
    }
  }, [profileData]);

  useEffect(() => {
    if (isLoggedIn) {
      getFavorites();
    }
  }, []);

  return (
    <Wrapper isSticky={isSticky} ref={wrapperRef}>
      <Inner isSticky={isSticky}>
        {isSticky && (
          <Link to={'/'}>
            <LogoOuter>
              <img
                src={Logo}
                alt="logo"
                style={{ height: width < 1439 ? 20 : 26.5 }}
              />
            </LogoOuter>
          </Link>
        )}
        {width > 1279 ? (
          <>
            <NavMenu>
              {menu.map(item => (
                <MenuItem
                  key={item.name}
                  slug={item.slug}
                  name={item.name}
                  submenu={item['submenu']}
                  active={isSticky}
                  currentLocation={location.pathname}
                />
              ))}
            </NavMenu>

            {isLoggedIn && profileData ? (
              <ButtonsContainer>
                <Link to={'/profile/saved'}>
                  <ButtonIconBookmark>
                    {favoritesData && favoritesData['meta']['total'] > 0 && (
                      <span>{favoritesData['meta']['total']}</span>
                    )}
                    <MenuBookmark />
                  </ButtonIconBookmark>
                </Link>
                <Link to={'/profile'}>
                  <UserActionButton isSticky={isSticky}>
                    <ButtonIcon isSticky={isSticky}>
                      <UserIcon />
                    </ButtonIcon>

                    {profileData.firstName + ' ' + profileData.lastName}
                  </UserActionButton>
                </Link>
              </ButtonsContainer>
            ) : (
              <ButtonsContainer>
                <Link to={'/login?redirect_to=profile/saved'}>
                  <ButtonIcon>
                    <MenuBookmark />
                  </ButtonIcon>
                </Link>
                <Link to={'/login?redirect_to=profile'}>
                  <UserActionButton
                    // onClick={() => dispatch(appActions.setLocation('/profile'))}
                    isSticky={isSticky}
                  >
                    <ButtonIcon isSticky={isSticky}>
                      <UserIcon />
                    </ButtonIcon>

                    {t(translations.header.userActionButton)}
                  </UserActionButton>
                </Link>
              </ButtonsContainer>
            )}
          </>
        ) : (
          <ActionsContainer>
            {isLoggedIn && profileData ? (
              <Link to={'/profile'}>
                <UserActionButton isSticky={isSticky}>
                  <ButtonIcon className={'mobileIcon'} isSticky={isSticky}>
                    <UserMobileIcon />
                  </ButtonIcon>
                </UserActionButton>
              </Link>
            ) : (
              <Link to={'/login?redirect_to=profile'}>
                <UserActionButton isSticky={isSticky}>
                  <ButtonIcon className={'mobileIcon'} isSticky={isSticky}>
                    <UserMobileIcon />
                  </ButtonIcon>
                </UserActionButton>
              </Link>
            )}

            <StyledBurger
              open={burgerOpened}
              onClick={() => setBurgerOpened(!burgerOpened)}
            >
              <div />
              <div />
              <div />
            </StyledBurger>
          </ActionsContainer>
        )}
      </Inner>

      <BurgerNavMenu open={burgerOpened} close={() => setBurgerOpened(false)} />
    </Wrapper>
  );
}
