import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as CloseVideoPopupIcon } from '../../assets/icons/btn-close-popup-video-icon.svg';

import { useSelector } from 'react-redux';

import { selectLocation } from '../../store/app/selectors';
import { actions as signUpByPhoneActions } from '../../store/signUpByPhone/slice';
import { actions as loginActions } from '../../store/login/slice';

import { useDispatch } from 'react-redux';

// styles
import { Popup, Wrapper, CloseItem } from './styles';

interface IProps {
  children: React.ReactChild;
  isPopup?: boolean;
  isSignUp?: boolean;
  isResetPass?: boolean;
  isLoginAndRegistration?: boolean;
  isRegisterSuccess?: boolean;
  isUnauthorised?: boolean;
  isVideoPopup?: boolean;
  closeModalPopup?: boolean;
  confirmEmailClose?: (data: boolean) => void;
  confirmVideoPopupClose?: (data: boolean) => void;
}

export function ModalLayout({
  children,
  isPopup,
  isSignUp,
  isResetPass,
  isLoginAndRegistration,
  isRegisterSuccess,
  isUnauthorised,
  isVideoPopup,
  closeModalPopup,
  confirmEmailClose,
  confirmVideoPopupClose,
}: IProps) {
  const history = useHistory();

  // selectors
  const currentLocation = useSelector(selectLocation) || '/';

  // dispatch
  const dispatch = useDispatch();

  const closeHandler = () => {
    if (isSignUp) {
      history.push(currentLocation);
    }

    if (confirmEmailClose && isRegisterSuccess) {
      confirmEmailClose(false);
      history.push('login');
    }

    if (isResetPass) {
      dispatch(signUpByPhoneActions.fetchFailed(''));
      history.push(currentLocation);
    }
    if (confirmVideoPopupClose && isVideoPopup) {
      confirmVideoPopupClose(false);
    }
  };
  const closeLoginHandler = () => {
    if (isLoginAndRegistration) {
      history.push(currentLocation);
      dispatch(loginActions.fetchFailed(''));
    }
  };

  return (
    <Popup
      open={!closeModalPopup}
      modal
      arrow={false}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      onClose={closeHandler}
      className={
        isLoginAndRegistration || isResetPass ? 'LoginAndRegistration' : ''
      }
    >
      {close => (
        <Wrapper
          isVideoPopup={isVideoPopup}
          className={
            isLoginAndRegistration || isResetPass
              ? 'LoginAndRegistrationWrapper'
              : ''
          }
        >
          {(isPopup || isResetPass || isRegisterSuccess) && (
            <CloseItem
              onClick={() =>
                isPopup
                  ? close()
                  : isResetPass || isRegisterSuccess
                  ? closeHandler()
                  : history.goBack()
              }
            >
              <CloseIcon />
            </CloseItem>
          )}
          {isVideoPopup && (
            <CloseItem
              className={'btn-close-video-popup'}
              onClick={() => closeHandler()}
            >
              <CloseVideoPopupIcon />
            </CloseItem>
          )}
          {(isPopup || isLoginAndRegistration) && (
            <CloseItem
              onClick={() => {
                isPopup
                  ? close()
                  : isLoginAndRegistration
                  ? closeLoginHandler()
                  : history.goBack();
              }}
            >
              <CloseIcon />
            </CloseItem>
          )}

          {(isPopup || isUnauthorised) && (
            <CloseItem
              onClick={() => {
                isPopup ? close() : history.push('/webinars');
              }}
            >
              <CloseIcon />
            </CloseItem>
          )}

          {children}
        </Wrapper>
      )}
    </Popup>
  );
}

export default ModalLayout;
