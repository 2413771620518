import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from '../../../utils/redux-injectors';

import axios from 'axios';

// assets
import { ReactComponent as Logo } from '../../../assets/images/logo-last.svg';
// import { ReactComponent as Cardio } from '../../../assets/images/NewCardio.svg';
import PreloaderImage from '../../../assets/images/Servier_preloader.png';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
  actions as appActions,
} from '../../../store/app/slice';

import { selectIsCookiesAccepted } from '../../../store/app/selectors';

// components
import NavBar from '../NavBar';

// hooks
import { useWindowSize } from '../../../hooks/useWindowSize';

// styles
import {
  Wrapper,
  TopBarContainer,
  TopBarInner,
  TopBar,
  TopInfo,
  Text,
  PreloaderWrap,
  NavBackBox,
  LogoWrapper,
  Cookies,
  CookiesWrapper,
  CookiesText,
  CookiesButton,
} from './styles';

// api breakpoint
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'api/v1/translations';

export function Header() {
  // inject reducer && saga
  useInjectReducer({ key: appSliceKey, reducer: appReducer });

  // location hook
  const location = useLocation();

  // window size watcher
  const { width } = useWindowSize();

  // dispatch
  const dispatch = useDispatch();
  const setCookiesAccepted = () => dispatch(appActions.setCookiesAccepted());

  // selectors
  const isAccepted = useSelector(selectIsCookiesAccepted);

  // fetch data from api
  const [translation, setTranslation] = React.useState({
    header_phone: '',
    header_text: '',
  });

  const [show, setShow] = useState('flex');

  const fetchTranslation = () => {
    axios
      .get(`${baseUrl}`)
      .then(response => {
        const translated = response.data;

        setTranslation(translated);
      })
      .catch(error => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    fetchTranslation();
    setTimeout(function () {
      setShow('none');
    }, 1500);
  }, []);

  const { header_text } = translation;

  const handleCookiesBtn = () => setCookiesAccepted();

  // @ts-ignore
  return (
    <Wrapper>
      <Cookies style={{ display: isAccepted === 'true' ? 'none' : 'flex' }}>
        <CookiesText>Цей веб-сайт використовує файли cookie.</CookiesText>
        <CookiesButton onClick={handleCookiesBtn}>Так</CookiesButton>
      </Cookies>
      <CookiesWrapper
        style={{ display: isAccepted === 'true' ? 'none' : 'block' }}
      ></CookiesWrapper>
      <PreloaderWrap style={{ display: show }}>
        <figure>
          <img src={PreloaderImage} alt="preloader" />
        </figure>
      </PreloaderWrap>
      {width > 1024 &&
        (location.pathname === '/' ||
          (location.pathname !== '/' &&
            location.pathname.includes('/auth'))) && (
          <TopBarContainer>
            <TopBarInner>
              <TopBar>
                <LogoWrapper to={'/'}>
                  <Logo />
                </LogoWrapper>

                <TopInfo>
                  <Text>{header_text}</Text>
                </TopInfo>
              </TopBar>
            </TopBarInner>
          </TopBarContainer>
        )}

      <NavBar />

      {(location.pathname !== '/' || width < 1025) && <NavBackBox />}
    </Wrapper>
  );
}
