import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.favorites || initialState;

export const selectFavoritesData = createSelector(
  [selectDomain],
  favoritesState => favoritesState.data,
);

export const selectFavoritesLoading = createSelector(
  [selectDomain],
  favoritesState => favoritesState.loading,
);

export const selectFavoritesDone = createSelector(
  [selectDomain],
  favoritesState => favoritesState.done,
);

export const selectFavoritesError = createSelector(
  [selectDomain],
  favoritesState => favoritesState.error,
);

export const selectFavoritesAdd = createSelector(
  [selectDomain],
  favoritesState => favoritesState.add,
);

export const selectFavoritesAddLoading = createSelector(
  [selectFavoritesAdd],
  favoritesAddState => favoritesAddState.loading,
);

export const selectFavoritesAddDone = createSelector(
  [selectFavoritesAdd],
  favoritesAddState => favoritesAddState.done,
);

export const selectFavoritesAddError = createSelector(
  [selectFavoritesAdd],
  favoritesAddState => favoritesAddState.error,
);

export const selectFavoritesRemove = createSelector(
  [selectDomain],
  favoritesState => favoritesState.remove,
);

export const selectFavoritesRemoveLoading = createSelector(
  [selectFavoritesRemove],
  favoritesRemoveState => favoritesRemoveState.loading,
);

export const selectFavoritesRemoveDone = createSelector(
  [selectFavoritesRemove],
  favoritesRemoveState => favoritesRemoveState.done,
);

export const selectFavoritesRemoveError = createSelector(
  [selectFavoritesRemove],
  favoritesRemoveState => favoritesRemoveState.error,
);
