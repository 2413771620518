import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/app/selectors';
import ModalLayout from '../layouts/ModalLayout';
import UnauthorizedMessage from '../components/features/UnauthorizedMessage';

export const PrivateRoute = ({ path, component: Component, ...rest }: any) => {
  const isLoggedIn = useSelector(selectIsAuthenticated);
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);

  useEffect(() => {
    if (isLoggedIn) setUserLoggedIn(true);
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={props => {
        if (userLoggedIn) return <Component {...props} {...rest} />;
        else
          return (
            <ModalLayout>
              <UnauthorizedMessage />
            </ModalLayout>
          );
      }}
    />
  );
};
