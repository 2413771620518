/*
 * Favorites Slice
 *
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  ActionTriggerProps,
  ISuccess,
  IFailure,
} from './types';
import { IProfileFavorites } from '../../types/profile/favorites';

// The initial state of the Login container
export const initialState: ContainerState = {
  data: null,
  loading: false,
  done: false,
  error: null,
  add: {
    loading: false,
    done: false,
    error: null,
  },
  remove: {
    loading: false,
    done: false,
    error: null,
  },
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    fetchTrigger(state, action: PayloadAction) {
      state.loading = true;
      state.done = false;
      state.error = null;
    },
    fetchSuccess(state, action: PayloadAction<IProfileFavorites>) {
      state.data = action.payload;
      state.done = true;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    fetchFulfilled(state) {
      state.loading = false;
      state.done = false;
    },
    fetchAddTrigger(state, action: PayloadAction<ActionTriggerProps>) {
      state.add.loading = true;
      state.add.done = false;
      state.add.error = null;
    },
    fetchAddSuccess(state, action: PayloadAction<ISuccess>) {
      state.add.done = true;
    },
    fetchAddFailed(state, action: PayloadAction<IFailure>) {
      state.add.error = action.payload.error;
    },
    fetchAddFulfilled(state) {
      state.add.loading = false;
      state.add.done = false;
    },
    fetchRemoveTrigger(state, action: PayloadAction<ActionTriggerProps>) {
      state.remove.loading = true;
      state.remove.done = false;
      state.remove.error = null;
    },
    fetchRemoveSuccess(state, action: PayloadAction<ISuccess>) {
      state.remove.done = true;
    },
    fetchRemoveFailed(state, action: PayloadAction<IFailure>) {
      state.remove.error = action.payload.error;
    },
    fetchRemoveFulfilled(state) {
      state.remove.loading = false;
      state.remove.done = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = favoritesSlice;
