import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.app || initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  appState => appState.token,
);

export const selectError = createSelector(
  [selectDomain],
  appState => appState.error,
);

export const selectIsCookiesAccepted = createSelector(
  [selectDomain],
  appState => appState.cookiesAccepted,
);

export const selectIsVideoPolicyAccepted = createSelector(
  [selectDomain],
  appState => appState.videoPolicyAccepted,
);

export const selectLocation = createSelector(
  [selectDomain],
  appState => appState.location,
);
