import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

import mq from '../../../utils/mediaQueriesBp';

interface IProps {
  open: boolean;
}

interface ISlideProps {
  category?: boolean;
  length?: number;
  active?: boolean;
}

const Wrapper = styled.div<IProps>`
  display: flex;
  visibility: ${p => (p.open ? 'visible' : 'hidden')};
  justify-content: flex-end;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
`;

const ModalContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${p => p.theme.colors.nav_purple};
  z-index: 2;
  color: white;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  padding: 17px 20px;
  overflow: auto;
  visibility: visible;

  ${mq('laptop')} {
    width: 70%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px;
  }
`;

const ModalOverlay = styled.div<IProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ open }) => (open ? '100%' : '0')};
  height: 100vh;
  background: rgba(0, 114, 186, 0.6);
  backdrop-filter: blur(10px);
  z-index: 1;
  ${props => props.theme.hover.transition}
`;

const ModalMenu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;

const ModalMenuItem = styled.div<ISlideProps>`
  font-weight: ${p => (p.active ? 800 : 400)};
  padding-bottom: 13px;
  text-align: center;
  cursor: pointer;
  color: ${p => (p.active ? p.theme.colors.cyan : 'white')};

  ${mq('tablet')} {
    padding-bottom: 20px;
  }

  svg {
    margin-right: 10px;

    path {
      fill: white;
    }
  }

  :hover {
    color: ${p => p.theme.colors.lightBlue};

    svg {
      path {
        fill: ${p => p.theme.colors.lightBlue};
      }
    }
  }
`;

const ModalMenuItemChildren = styled.div<ISlideProps>`
  height: ${p => (p.active && p.length ? `${p.length * 39}px` : 0)};
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
`;

const ModalMenuItemChildrenLink = styled.div`
  font-weight: 300;
  padding-bottom: 13px;
  text-align: center;
  cursor: pointer;
  color: white;
  background-color: ${p => p.theme.colors.primary};

  ${mq('tablet')} {
    padding-bottom: 20px;
  }

  :hover {
    color: ${p => p.theme.colors.lightBlue};
  }
`;

const LogoOuter = styled.div`
  display: flex;
  align-items: flex-end;
  width: 170px;

  svg {
    width: 100%;
    object-fit: cover;
  }
`;

export {
  Wrapper,
  ModalContainer,
  ModalOverlay,
  ModalMenu,
  ModalMenuItem,
  ModalMenuItemChildren,
  ModalMenuItemChildrenLink,
  LogoOuter,
  Link,
};
