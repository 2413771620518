import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.profile || initialState;

export const selectProfile = createSelector(
  [selectDomain],
  profileState => profileState.fetch,
);

export const selectUpdate = createSelector(
  [selectDomain],
  profileState => profileState.update,
);

export const selectUpdateAvatar = createSelector(
  [selectDomain],
  profileState => profileState.updateAvatar,
);

export const selectProfileData = createSelector(
  [selectProfile],
  profileFetchState => profileFetchState.data,
);

export const selectProfileLoading = createSelector(
  [selectProfile],
  profileFetchState => profileFetchState.loading,
);

export const selectProfileDone = createSelector(
  [selectProfile],
  profileFetchState => profileFetchState.done,
);

export const selectProfileError = createSelector(
  [selectProfile],
  profileFetchState => profileFetchState.error,
);

export const selectUpdateLoading = createSelector(
  [selectUpdate],
  profileUpdateState => profileUpdateState.loading,
);

export const selectUpdateDone = createSelector(
  [selectUpdate],
  profileUpdateState => profileUpdateState.done,
);

export const selectUpdateError = createSelector(
  [selectUpdate],
  profileUpdateState => profileUpdateState.error,
);

export const selectUpdateAvatarLoading = createSelector(
  [selectUpdateAvatar],
  profileUpdateAvatarState => profileUpdateAvatarState.loading,
);

export const selectUpdateAvatarDone = createSelector(
  [selectUpdateAvatar],
  profileUpdateAvatarState => profileUpdateAvatarState.done,
);

export const selectUpdateAvatarError = createSelector(
  [selectUpdateAvatar],
  profileUpdateAvatarState => profileUpdateAvatarState.error,
);
