import styled from 'styled-components/macro';

import Popup from 'reactjs-popup';

import mq from '../../utils/mediaQueriesBp';

interface IsUnauthorised {
  isUnauthorised?: boolean | null;
  isVideoPopup?: boolean | null;
}

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(212, 222, 242, 0.6);
    backdrop-filter: blur(10px);
    height: 100vh;
  }

  &-content {
    position: relative;
    margin: auto;
    width: 90%;
    max-width: 690px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    ${mq('laptop')} {
      max-width: 590px;
    }

    ${mq('desktop')} {
      max-width: 690px;
    }

    // -webkit-animation: anvil 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    &.LoginAndRegistration {
      &-content {
        max-width: 500px;

        ${mq('desktop')} {
          width: 100%;
        }
      }
    }
  }

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
`;

const Wrapper = styled.div<IsUnauthorised>`
  display: flex;
  flex: 1;
  padding: 50px 20px 20px;

  ${mq('tablet')} {
    padding: 20px 40px;
  }

  ${mq('laptop')} {
    padding: 30px 72px;
  }

  ${props =>
    props.isVideoPopup &&
    `
      padding: 0 !important;
      height: 65vh;
  `}

  &.LoginAndRegistrationWrapper {
    padding: 0;
  }
`;

const CloseItem = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 999;

  ${mq('tablet')} {
    top: 0;
    right: -48px;
  }

  svg {
    path {
      fill: ${p => p.theme.colors.primary};

      ${mq('tablet')} {
        fill: #ffffff;
      }
    }
  }

  &.btn-close-video-popup {
    top: -50px;
    right: 0;

    ${mq('laptop')} {
      top: 0;
      right: -48px;
    }

    svg {
      circle {
        transition: opacity 0.5s;
      }
    }
    &:hover {
      svg {
        circle {
          opacity: 1;
        }
      }
    }
  }
`;

export { StyledPopup as Popup, Wrapper, CloseItem };
