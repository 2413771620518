/*
 * Profile Slice
 *
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  ActionUpdateTrigger,
  ActionUpdateAvatarTrigger,
} from './types';
import { IProfile } from '../../types/profile';

// The initial state of the Login container
export const initialState: ContainerState = {
  fetch: {
    data: null,
    loading: false,
    done: false,
    error: null,
  },
  update: {
    loading: false,
    done: false,
    error: null,
  },
  updateAvatar: {
    loading: false,
    done: false,
    error: null,
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchTrigger(state) {
      state.fetch.loading = true;
      state.fetch.done = false;
      state.fetch.error = null;
    },
    fetchSuccess(state, action: PayloadAction<IProfile>) {
      state.fetch.data = action.payload;
      state.fetch.done = true;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.fetch.error = action.payload;
    },
    fetchFulfilled(state) {
      state.fetch.loading = false;
      state.fetch.done = false;
    },
    fetchUpdateTrigger(state, action: PayloadAction<ActionUpdateTrigger>) {
      state.update.loading = true;
      state.update.done = false;
      state.update.error = null;
    },
    fetchUpdateSuccess(state, action: PayloadAction<IProfile>) {
      state.fetch.data = action.payload;
      state.update.done = true;
    },
    fetchUpdateFailed(state, action: PayloadAction<string>) {
      state.update.error = action.payload;
    },
    fetchUpdateFulfilled(state) {
      state.update.loading = false;
      state.update.done = false;
    },
    fetchUpdateAvatarTrigger(
      state,
      action: PayloadAction<ActionUpdateAvatarTrigger>,
    ) {
      state.updateAvatar.loading = true;
      state.updateAvatar.done = false;
      state.updateAvatar.error = null;
    },
    fetchUpdateAvatarSuccess(state) {
      state.updateAvatar.done = true;
    },
    fetchUpdateAvatarFailed(state, action: PayloadAction<string>) {
      state.updateAvatar.error = action.payload;
    },
    fetchUpdateAvatarFulfilled(state) {
      state.updateAvatar.loading = false;
      state.updateAvatar.done = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;
