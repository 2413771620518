import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as profileActions } from './slice';
import {
  fetchProfileService,
  updateProfileService,
  updateAvatarService,
} from './services';

/**
 * Profile request/response handler
 */
export function* fetchProfileSaga() {
  try {
    const { data } = yield call(fetchProfileService);

    yield put(profileActions.fetchSuccess(data));
  } catch (err) {
    yield put(profileActions.fetchFailed(err));
  } finally {
    yield put(profileActions.fetchFulfilled());
  }
}

export function* fetchProfileUpdateSaga({
  payload,
}: ReturnType<typeof profileActions.fetchUpdateTrigger>) {
  try {
    const { data } = yield call(updateProfileService, payload);

    yield put(profileActions.fetchUpdateSuccess(data));
  } catch (err) {
    yield put(profileActions.fetchUpdateFailed(err));
  } finally {
    yield put(profileActions.fetchUpdateFulfilled());
  }
}

export function* fetchProfileUpdateAvatarSaga({
  payload,
}: ReturnType<typeof profileActions.fetchUpdateAvatarTrigger>) {
  try {
    yield call(updateAvatarService, payload);

    yield put(profileActions.fetchUpdateAvatarSuccess());
    yield put(profileActions.fetchTrigger());
  } catch (err) {
    yield put(profileActions.fetchUpdateAvatarFailed(err));
  } finally {
    yield put(profileActions.fetchUpdateAvatarFulfilled());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* profileSaga() {
  yield takeLatest(profileActions.fetchTrigger.type, fetchProfileSaga);
  yield takeLatest(
    profileActions.fetchUpdateTrigger.type,
    fetchProfileUpdateSaga,
  );
  yield takeLatest(
    profileActions.fetchUpdateAvatarTrigger.type,
    fetchProfileUpdateAvatarSaga,
  );
}
