import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

import mq from '../../../utils/mediaQueriesBp';

import ResponsiveContainer from '../ResponsiveContainer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBarContainer = styled.div`
  width: 100%;
  background-color: ${p => p.theme.colors.nav_purple};
  height: 57px;

  ${mq('middle')} {
    height: 69px;
  }
`;

const TopBarInner = styled(ResponsiveContainer)``;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  // padding-top: 10px;
  // padding-bottom: 10px;
`;

const LogoWrapper = styled(NavLink)`
  text-decoration: none;
  width: 230px;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 400px;

  ${mq('desktop')} {
    max-width: unset;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 19px;
  color: white;
  text-align: right;
`;

const CookiesWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 1005;
`;

const Cookies = styled.div`
  width: 100%;
  height: 80px;
  background: ${props => props.theme.colors.lightBlue};
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15%;
  z-index: 1006;
`;

const CookiesText = styled.div`
  color: ${props => props.theme.colors.font_main};
  font-weight: 500;
  font-size: 18px;
`;

const CookiesButton = styled.div`
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.secondary};
  color: white;
  cursor: pointer;
`;

const NavBackBox = styled.div`
  width: 100%;
  height: 57px;

  ${mq('middle')} {
    height: 69px;
  }
`;

const PreloaderWrap = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 1006;
  display: flex;
  align-items: center;
  justify-content: center;

  figure {
    mask-image: linear-gradient(90deg, #0000, #0000, #fff, #fff, #0000, #0000);
    mask-size: 600% 100%;
    animation: fade-slide 2s infinite;

    img {
      max-width: 300px;
    }
  }

  @keyframes fade-slide {
    from {
      mask-position: right;
    }
    50% {
      mask-position: center;
    }
    to {
      mask-position: left;
    }
  }
`;

export {
  Wrapper,
  TopBarContainer,
  TopBarInner,
  TopBar,
  TopInfo,
  Text,
  NavBackBox,
  LogoWrapper,
  PreloaderWrap,
  Cookies,
  CookiesWrapper,
  CookiesButton,
  CookiesText,
};
