import styled from 'styled-components/macro';

// utils
import mq from '../../../utils/mediaQueriesBp';

// components
import P from '../../../atoms/P';
import { ReactComponent as ListCheck } from '../../../assets/icons/list-check.svg';

import ResponsiveContainer from '../../layout/ResponsiveContainer';

const Wrapper = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-top: 16px;

  align-items: center;
  justify-content: center;
  align-self: center;
  position: relative;
  z-index: 2;
`;

const Title = styled(P)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 22px;
`;

const SubTitle = styled.div`
  text-align: center;
  margin-bottom: 22px;
`;

const MaterialsList = styled.ul`
  // display: flex
  display: block;
  margin: 0 auto;
  max-width: 320px;
`;

const MaterialsListItem = styled.li`
  position: relative;

  &:before {
    content: none;
  }
`;

const ContentWrapper = styled.div`
  margin-bottom: 36px;
  color: ${p => p.theme.colors.font_main};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${mq('laptop')} {
    flex-direction: row;
  }

  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 204px;
    padding: 20px 15px;

    &:first-of-type {
      margin-bottom: 10px;

      ${mq('laptop')} {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }
`;

const ListCheckIcon = styled(ListCheck)`
  position: absolute;
  left: 0;
  top: 2px;
`;

export {
  Wrapper,
  Title,
  ButtonWrapper,
  SubTitle,
  ContentWrapper,
  MaterialsList,
  MaterialsListItem,
  ListCheckIcon,
};
