export const menu = [
  {
    name: 'webinars',
    slug: '/webinars',
  },
  {
    name: 'applications-and-calculators',
    slug: '/applications-and-calculators',
  },
  {
    name: 'new-clinical-cases',
    slug: '/clinical-cases',
  },
  {
    name: 'share-with-patient',
    slug: '/doctor-and-visitor',
  },
];
