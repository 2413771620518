import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

import mq from '../../../../utils/mediaQueriesBp';

interface IProps {
  active: boolean;
  highlighted?: boolean;
}

const NavItemWrapper = styled.div<IProps>`
  position: relative;

  ${p =>
    p.active &&
    `
    :last-child {
      margin-right: 10px;
    }
  `}
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;

const NavItem = styled.div<IProps>`
  padding: 24px 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  ${p => p.theme.hover.transition}

  ${mq('large')} {
    font-size: 14px;
    line-height: 18px;
    padding: 25px 10px 26px;
  }

  ${p =>
    p.active &&
    p.highlighted &&
    `
    border-bottom-color: ${p.theme.colors.cyan};
  `}

  ${p =>
    !p.active &&
    p.highlighted &&
    `
    color: white;
  `}
  
   &:hover {
    color: ${p => p.theme.colors.cyan};
  }
`;

const Content = styled.div<IProps>`
  position: absolute;
  background-color: ${p => (p.active ? p.theme.colors.primary : 'white')};
`;

const Child = styled(NavItem)<IProps>`
  display: flex;
  flex: 1;
  border: none;
  min-width: 180px;

  ${mq('large')} {
    min-width: 250px;
  }

  &:hover {
    color: ${p => (p.active ? p.theme.colors.primary : 'white')};
    background-color: ${p =>
      p.active ? p.theme.colors.lightBlue : p.theme.colors.cyan};
  }
`;

export { NavItemWrapper, NavItem, Content, Child, Link };
