import { useLocation } from 'react-router-dom';
import { setAuthAccessToken } from '../../../store/app/saga';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function AuthPage() {
  const query = useQuery();

  let token: any;
  let rememberToken: any;
  let savedCookieAccepted: any;
  let redirect: any;

  token = query.get('savedToken');
  rememberToken = query.get('savedRememberToken');
  savedCookieAccepted = query.get('savedCookieAccepted');
  redirect = query.get('redirect');

  if (token && '' !== token) {
    setAuthAccessToken(token);
  }

  if (rememberToken && '' !== rememberToken) {
    localStorage.setItem('remember_token', rememberToken);
  }

  if (savedCookieAccepted && '' !== savedCookieAccepted) {
    localStorage.setItem('cookies_accepted', savedCookieAccepted);
  }

  window.location = redirect;

  return null;
}
