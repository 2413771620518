import { $apiClient } from 'utils/request';
import { ActionTriggerProps } from './types';

const url = 'api/v1/profile/favorites';

function payloadTypeID(payload) {
  if (payload.webinarId) {
    return { webinarId: payload.webinarId };
  }
  if (payload.clinicalCaseId) {
    return { clinicalCaseId: payload.clinicalCaseId };
  }
  return { contentId: payload.contentId };
}

export function favoritesService() {
  return $apiClient.get(url);
}

export function favoritesAddService(payload: ActionTriggerProps) {
  return $apiClient.post(url, payloadTypeID(payload));
}

export function favoritesRemoveService(payload: ActionTriggerProps) {
  return $apiClient.delete(url, { data: payloadTypeID(payload) });
}
