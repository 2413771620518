const breakpoints = {
  tablet: 480,
  laptop: 768,
  desktop: 1025,
  middle: 1280,
  large: 1440,
};

const mq = n => {
  const bpArray = Object.keys(breakpoints).map(key => [key, breakpoints[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

export default mq;
