/*
 * App Slice
 *
 */

import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Login container
export const initialState: ContainerState = {
  token: localStorage.getItem('access_token'),
  error: null,
  cookiesAccepted: localStorage.getItem('cookies_accepted'),
  videoPolicyAccepted: localStorage.getItem('video_policy_accepted'),
  location: '/',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    autoLoginTrigger(state) {
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    logoutTrigger(state) {
      state.error = null;
    },
    logoutSuccess(state) {
      state.token = null;
    },
    logoutFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setCookiesAccepted(state) {
      state.cookiesAccepted = 'true';
      localStorage.setItem('cookies_accepted', 'true');
    },
    setVideoPolicyAccepted(state) {
      state.videoPolicyAccepted = 'true';
      localStorage.setItem('video_policy_accepted', 'true');
    },
    setLocation(state, action: PayloadAction<string>) {
      state.location = action.payload;
    },
    checkAuthStatusTrigger(state) {
      state.error = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = appSlice;
