import React from 'react';

import { useHistory } from 'react-router-dom';

// styles
import {
  Wrapper,
  Title,
  SubTitle,
  ButtonWrapper,
  ContentWrapper,
  MaterialsList,
  MaterialsListItem,
  ListCheckIcon,
} from './styles';

import {
  ButtonGroups,
  ButtonSizes,
  ButtonTypes,
} from '../../../atoms/Button/types';

// components
import Button from '../../../atoms/Button';
import { translations } from '../../../locales/i18n';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLocation } from '../../../store/app/selectors';

export const UnauthorizedMessage = () => {
  // locales hook
  const { t } = useTranslation();

  const history = useHistory();

  let currentLocation: string | null | undefined = useSelector(selectLocation);

  const redirectToLogin = () => {
    return history.push('/login');
  };

  const redirectToPreviousStep = () => {
    if (currentLocation) {
      if (currentLocation?.includes('/webinars')) {
        return history.push('/webinars');
      }
      return history.goBack();
    }
  };

  const MaterialsListArr = [
    t(translations.unauthorizedMessage.materialsList.list_item_1),
    t(translations.unauthorizedMessage.materialsList.list_item_2),
    t(translations.unauthorizedMessage.materialsList.list_item_3),
    t(translations.unauthorizedMessage.materialsList.list_item_4),
  ];

  return (
    <>
      <Wrapper>
        <Title>Шановний відвідувач!</Title>
        <ContentWrapper>
          <SubTitle>
            Наш сайт призначений виключно для спеціалістів сфери охорони
            здоров’я. Після авторизації вам буде відкрито повний доступ до всіх
            матеріалів, включаючи:
          </SubTitle>

          <MaterialsList>
            {MaterialsListArr.map((n, i) => (
              <MaterialsListItem key={i}>
                <ListCheckIcon />
                {n}
              </MaterialsListItem>
            ))}
          </MaterialsList>
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            text={'Хочу авторизуватися'}
            size={ButtonSizes.Large}
            group={ButtonGroups.Primary}
            type={ButtonTypes.Button}
            click={redirectToLogin}
          />

          <Button
            text={'Іншого разу'}
            size={ButtonSizes.Large}
            group={ButtonGroups.OutlinedPrimary_2}
            type={ButtonTypes.Button}
            click={redirectToPreviousStep}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default UnauthorizedMessage;
