export const findLocationPath = path => {
  let result: any = null;
  const splitted = path.split('/').splice(1);

  if (path === '/') {
    result = 'main';
  } else {
    result = splitted[splitted.length - 1];
  }

  return result;
};
