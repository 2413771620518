import React, { useState } from 'react';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectProfile } from 'store/profile/selectors';
import { selectIsAuthenticated } from 'store/app/selectors';

// styles
import { NavItemWrapper, NavItem, Content, Child, Link } from './styles';

interface ISubmenuItem {
  name: string;
  slug: string;
}

interface MenuItemProps {
  name: string;
  slug: string;
  submenu?: Array<ISubmenuItem>;
  currentLocation?: any;
  active: boolean;
}

export default function MenuItem({
  name,
  slug,
  submenu,
  active,
  currentLocation,
}: MenuItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  // locales hook
  const { t } = useTranslation();

  // selector
  const profileInfo = useSelector(selectProfile);
  const isLoggedIn = useSelector(selectIsAuthenticated);

  // mouse over handler
  const onHoverIn = () => {
    setIsOpen(true);
  };

  // mouse leave handler
  const onHoverOff = () => {
    setIsOpen(false);
  };
  const activeMenuItemIsActive =
    slug === '/'
      ? currentLocation === slug
      : currentLocation && currentLocation.includes(slug);

  const isLinked = true;
  const handleScrollToStats = e => {
    if (e === '/#contacts') {
      window.scrollTo({
        top: 1000000,
      });
    }
    if (e === '/#calculators') {
      window.scrollTo({
        top: 950,
      });
    }
    if (e === '/#last-news') {
      window.scrollTo({
        top: 1500,
      });
    }
    if (e === '/#events') {
      window.scrollTo({
        top: 2800,
      });
    }
  };
  return (
    <NavItemWrapper
      active={active}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOff}
    >
      {isLinked ? (
        <Link to={slug}>
          <NavItem highlighted={activeMenuItemIsActive} active={active}>
            {t(translations.nav[name])}
          </NavItem>
        </Link>
      ) : (
        <NavItem highlighted={activeMenuItemIsActive} active={active}>
          {t(translations.nav[name])}
        </NavItem>
      )}
      {isOpen && (
        <Content active={active}>
          {submenu &&
            submenu.map(item => (
              <Link
                key={item.name}
                to={item.slug}
                // @ts-ignore;
                onClick={() => {
                  handleScrollToStats(item.slug);
                  if (isLoggedIn && profileInfo?.data?.id) {
                    const tagManagerArgs = {
                      gtmId: process.env.REACT_APP_GTM_ID,
                      dataLayer: {
                        event: 'menu_header_click', //Статическое значение.
                        category: 'Menu Header Click', //Статическое значение
                        action: t(translations.nav[name]), //Название раздела меню (верхний уровень) к которому принадлежит пункт меню.
                        label: t(translations.nav[item.name]), //Название пункта меню, по которому кликнул пользователь.
                        uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
                      },
                    };

                    TagManager.dataLayer(tagManagerArgs);
                  }
                }}
              >
                <Child
                  highlighted={
                    currentLocation &&
                    currentLocation.includes(item.slug) &&
                    item.name !== 'questions-and-answers' &&
                    item.name !== 'contacts' &&
                    item.name !== 'debating-society'
                  }
                  active={active}
                >
                  {t(translations.nav[item.name])}
                </Child>
              </Link>
            ))}
        </Content>
      )}
    </NavItemWrapper>
  );
}
