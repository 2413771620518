import { call, put, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { $apiClient } from 'utils/request';
import { actions } from './slice';
import { actions as profileActions } from 'store/profile/slice';
import refreshTokenService from './services';

// auth access token handler
export function setAuthAccessToken(token) {
  localStorage.setItem('access_token', token);
  $apiClient.defaults.headers.Authorization = `Bearer ${token}`;
}

export function deleteAuthAccessToken() {
  localStorage.removeItem('access_token');
  delete $apiClient.defaults.headers.Authorization;
}

// auth check state
export function* authCheckState() {
  const token = yield localStorage.getItem('access_token');
  const rememberToken = yield localStorage.getItem('remember_token');

  if (token) {
    const decodedInfo = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    // @ts-ignore
    console.log(decodedInfo.exp);
    console.log(Math.round(currentTime));

    // @ts-ignore
    if (decodedInfo.exp > currentTime) {
      yield call(setAuthAccessToken, token);
      yield put(actions.loginSuccess(token));
    } else if (rememberToken) {
      try {
        const { accessToken } = yield call(refreshTokenService, rememberToken);

        yield call(setAuthAccessToken, accessToken);
        yield put(actions.loginSuccess(accessToken));
        yield put(profileActions.fetchTrigger());
      } catch (err) {
        console.log(err);
        yield put(actions.logoutTrigger());
      }
    } else {
      yield put(actions.logoutTrigger());
    }
  } else {
    yield put(actions.logoutTrigger());
  }
}

// auth login success
export function* authLoginSuccess({
  payload,
}: ReturnType<typeof actions.loginSuccess>) {
  yield setAuthAccessToken(payload);
}

// auth logout
export function* authLogout() {
  try {
    yield call(deleteAuthAccessToken);
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFail(error));
  }
}

// auth login success
export function cookiesAccepted() {
  localStorage.setItem('cookies_accepted', 'true');
}

/**
 * Root saga manages watcher lifecycle
 */
export function* appSaga() {
  yield takeLatest(actions.loginSuccess.type, authLoginSuccess);
  yield takeLatest(actions.logoutTrigger.type, authLogout);
  yield takeLatest(actions.checkAuthStatusTrigger.type, authCheckState);
}
