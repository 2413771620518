import { ButtonGroups, ButtonSizes, ButtonTypes, IProps } from './types';

export const defaultProps: IProps = {
  text: '',
  type: ButtonTypes.Button,
  loading: false,
  group: ButtonGroups.Primary,
  size: ButtonSizes.Regular,
  click: () => {},
  disabled: false,
};
